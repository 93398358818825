import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Select, MenuItem, FormControl, InputLabel,
  Tooltip, Button, IconButton
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserLogsAsync, selectUserLogs } from '../adminSlice';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';

const payloadOptions = [
  { label: 'User Access Logs', value: 'user_access' },
  { label: 'Admin Access Logs', value: 'admin_access' },
  { label: 'Admin Action Logs', value: 'admin_action' },
  { label: 'User Session Logs', value: 'user_session' },
  { label: 'Email Blast Logs', value: 'email_blast' },
];

const UserLogs = () => {
  const [selectedLogType, setSelectedLogType] = useState('user_access');
  const [lastRefreshed, setLastRefreshed] = useState(null);

  const dispatch = useDispatch();
  const userLogs = useSelector(selectUserLogs);

  const fetchLogs = () => {
    dispatch(fetchUserLogsAsync(selectedLogType));
    setLastRefreshed(new Date().toLocaleString());
  };

  useEffect(() => {
    fetchLogs(); 

    const interval = setInterval(() => {
      fetchLogs();
    }, 30000);  

    return () => clearInterval(interval);
  }, [selectedLogType, dispatch]);

  const handleChange = (event) => {
    setSelectedLogType(event.target.value);
  };

  const exportToCSV = () => {
    const { activityLogs, metadataColumns  } = userLogs?.data?.data || {};
    if (!activityLogs || activityLogs.length === 0) return;

    const headers = [
      'Timestamp',
      'User Email',
      'Activity Log',
      ...(metadataColumns ? metadataColumns.map(col =>
        col.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())) : [])
    ];

    const csvRows = [headers.join(',')];

    activityLogs.forEach((log) => {
      const row = [
        log.timestamp,
        log.userEmail,
        log.activityLog,
        ...(metadataColumns ? metadataColumns.map(col => {
          const metaDataKey = col.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
          return log.metaData?.[metaDataKey] || '-';
        }) : [])
      ];
      csvRows.push(row.map(item => `"${item}"`).join(','));
    });

    const blob = new Blob([csvRows.join('\n')], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedLogType}_logs.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { activityLogs, metadataColumns,lastRefresh } = userLogs?.data?.data || {};

  const headers = [
    'Timestamp',
    'User Email',
    'Activity Log',
    ...(metadataColumns ? metadataColumns.map(col =>
      col.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())) : [])
  ];

  return (
    <Box p={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">User Activity Logs Viewer</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" color="text.secondary">
            <i><b>Last Refreshed:</b> {lastRefresh || 'Not yet refreshed'}</i>
          </Typography>
          <Tooltip title="Refresh Logs">
            <IconButton onClick={fetchLogs}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <FormControl sx={{ width: '30%' }}>
          <InputLabel id="log-type-label">Select Log Type</InputLabel>
          <Select
            labelId="log-type-label"
            value={selectedLogType}
            label="Select Log Type"
            onChange={handleChange}
          >
            {payloadOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Tooltip title="Export Logs to CSV" placement="left">
          <Button
            variant="outlined"
            size="small"
            startIcon={<FileDownloadOutlinedIcon />}
            onClick={exportToCSV}
          >
            Export to CSV
          </Button>
        </Tooltip>
      </Box>

      {userLogs && (
        <TableContainer sx={{ height: "26rem" }} component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headers.map((head, idx) => (
                  <TableCell key={idx}><strong>{head}</strong></TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {activityLogs && activityLogs.length > 0 ? (
                activityLogs.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{log.timestamp}</TableCell>
                    <TableCell>{log.userEmail}</TableCell>
                    <TableCell>{log.activityLog}</TableCell>
                    {metadataColumns.map((col, i) => {
                      const metaDataKey = col.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
                      return (
                        <TableCell key={i}>{log.metaData?.[metaDataKey] || '-'}</TableCell>
                      );
                    })}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={headers.length} align="center">No data available</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default UserLogs;
