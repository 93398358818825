import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import Home from "./pages/Home";
import Protected from "./features/auth/components/Protected";
import AdminSideBar from './features/admin/components/adminSideBar';
import AdminUsers from './features/admin/components/adminUsers';
import AdminRegister from "./features/admin/components/adminRegister";
import AdminFromUserRegister from "./features/admin/components/adminFromUserRegister";
import AdminFromUsers from "./features/admin/components/adminFromUsers";
import AdminHome from "./features/admin/components/adminHome";
import PersonIcon from '@mui/icons-material/Person';
import UserHome from './features/user/components/userHome'
import { setLoggedInUser } from "./features/auth/authSlice";
import { refreshAccessToken, isAccessTokenExpired } from "./features/auth/authApi";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import jwt_decode from 'jwt-decode'
import { ToastContainer } from 'react-toastify';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import LogoutPage from "./pages/LogoutPage";
import UserLayout from "./features/user/components/userLayout";
import EmailCustomize from "./features/user/components/emailCustomize";
import PreviewEmail from "./features/user/components/previewEmail";
import CircularProgress from '@mui/material/CircularProgress';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AdminTemplateControl from "./features/admin/components/adminTemplateControl";
import AssignmentIcon from '@mui/icons-material/Assignment';
import UserLogs from "./features/admin/components/userLogs";
 
const dummySideBarData = [
  {
    text: "Users",
    navUrl: "/admin",
    icon: <PersonIcon />
  },
  {
    text: "Resgister Users",
    navUrl: "/admin/registerUser",
    icon: <PersonAddAlt1Icon />
  },
  {
    text: "From Users",
    navUrl: "/admin/FromUsers",
    icon: <PersonOutlineOutlinedIcon />
  },
  {
    text: "Resgister From Users",
    navUrl: "/admin/registerFromUser",
    icon: <PersonAddAltIcon />
  },
  {
    text: "Template Control",
    navUrl: "/admin/usertemplates",
    icon: <AddchartOutlinedIcon />
  },
  {
    text: "User Activity Logs",
    navUrl: "/admin/userlogs",
    icon: <AssignmentIcon />
  },

];

const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginPage />
  },
  {
    path: '/home',
    element: <Protected><Home /></Protected>
  },
  {
    path: '/user',
    element: <Protected><UserLayout /></Protected>,
    children: [
      {
        path: '',
        element: <Protected><UserHome /></Protected>
      },
      {
        path: 'emailcustomize',
        element: <Protected><EmailCustomize /></Protected>
      },
      {
        path: 'previewEmail',
        element: <Protected><PreviewEmail /></Protected>
      },
    ]
  },
  {
    path: '/admin',
    element: <Protected><AdminHome /></Protected>,
    children: [
      {
        element: <Protected><AdminSideBar item={dummySideBarData} /></Protected>,
        children: [
          {
            path: '',
            element: <Protected><AdminUsers /></Protected>
          },
          {
            path: 'registerUser',
            element: <Protected><AdminRegister /></Protected>
          },
          {
            path: 'registerFromUser',
            element: <Protected><AdminFromUserRegister /></Protected>
          },
          {
            path: 'FromUsers',
            element: <Protected><AdminFromUsers /></Protected>
          },
          {
            path: 'usertemplates',
            element: <Protected><AdminTemplateControl /></Protected>
          },
          {
            path: 'userlogs',
            element: <Protected><UserLogs /></Protected>
          },
         
        ]
      },
    ]
  },
  {
    path: '/logout',
    element: <Protected><LogoutPage /></Protected>
  }

]);


function App() {
  const spinnerContainerStyle = {
    width: '90%',
    marginLeft: '5%',
    marginTop: '10%',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
  };
  const dispatch = useDispatch()
  const [spinnerState, setSpinnerState] = useState(true);

  useEffect(()=>{
    setSpinnerState(false)
  })

  useEffect(() => {
    const token = Cookies.get('accessToken');
   

    if (token) {
      const decodedToken = jwt_decode(token);
      if (isAccessTokenExpired(decodedToken)) {
        const refreshToken = Cookies.get('refreshToken');
        if (!refreshToken) {
          return 
        }
        refreshAccessToken(refreshToken)
      }
      const userData = decodedToken.email;
      console.log(userData)
      dispatch(setLoggedInUser(userData));
     }
  }, [dispatch]);


  return (
    <>
      {spinnerState ? (
        <div style={spinnerContainerStyle}>
          <CircularProgress size={50} />
        </div>
      ) : (<div>
        <ToastContainer />
        <RouterProvider router={router} />
      </div>
      )
      }
    </>

  );
}

export default App;
