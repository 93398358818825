import Cookies from "js-cookie";
import { urls } from "../../url";
import { toast } from 'react-toastify';
import { checkAccessToken } from "../auth/authApi";

const TOASTER_TIMEOUT = 1000;

const handleApiError = (error) => {

  const response = error;
  if (response) {
    if (response.status === 400) {
      handleUnauthorizedError();
    } else if (response.status === 401) {
      handleBadRequestError(response);
    }
    else {
      console.log(response)
    }
  }
};

const handleBadRequestError = async (response) => {
  const responseError = await response.data?.message || 'Something went wrong pleaase Login again';
  toast.error(responseError, {
    position: 'top-right',
    autoClose: 3000,
  });
  setTimeout(() => {
    window.location.reload();
    // Cookies.remove('accessToken');
  }, 1000);
};

const handleUnauthorizedError = () => {
  toast.error('Session expired, Please Login Again!', {
    position: 'top-right',
    autoClose: TOASTER_TIMEOUT,
  });

  setTimeout(() => {
    window.location.reload();
    Cookies.remove('accessToken');
  }, 3000);
};

export async function CreateUser(userData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": userData };
    return new Promise(async (resolve) => {
      const response = await fetch(urls.registeruser, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });

      const data = await response.json();
      resolve({ data });

      // Handling success message (assuming no error)
      toast.success('Registration Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    });
  } catch (error) {
    handleApiError(error);
  }
}

export async function CreateDomain(domainData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": domainData };
    const response = await fetch(urls.fromUserDomainRegister, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const data = await response.json();

    if (response.status === 400) {
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
    else {
      toast.success('Domain Creation Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }



    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function EditDomain(domainData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": domainData };
    const response = await fetch(urls.fromUserDomainUpdate, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const data = await response.json();

    // if (response.status === 401) {
    //   handleUnauthorizedError();
    // } else if (response.status === 400) {
    //   handleBadRequestError(data);
    // } else {

    if (response.status === 400) {
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
    else {
      toast.success('Domain Edited Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }

    // }

    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function fetchAllUsers() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const response = await fetch(urls.getUsers, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function fetchAllDomains() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const response = await fetch(urls.fromUserDomainData, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function fetchAllFromUsers() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const response = await fetch(urls.getFromUsers, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function fetchDomainData(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": { "domainId": id } };
    const response = await fetch(urls.fromUserDomainData, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function removeFromUser(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { data: { id: id } };
    const response = await fetch(urls.deleteFromUser, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    toast.success('User Deletion Successful!', {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT
    });


    return data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function removeUser(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { data: { id: id } };
    const response = await fetch(urls.deleteUser, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    toast.success('User Deletion Successful!', {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT
    });

    return data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function CreateFromUser(fromEmailData) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": { "users": [fromEmailData] } };
    const response = await fetch(urls.registerFromUser, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    });

    const data = await response.json();
    
    if (response.status === 400) {
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
    else {
      toast.success('From User Creation Successful!', {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
    return data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function fecthUserStatistics() {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const response = await fetch(urls.fetchUserStatistics, {
      method: "GET",
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function fecthUserTemplates(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": { "userId":id } };
    const response = await fetch(urls.fetchUserTemplates, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}

export async function removeUserTemplate(id) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { data: { templateId: id } };
    const response = await fetch(urls.deleteUserTemplates, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();
    toast.success(data.data.message, {
      position: 'top-right',
      autoClose: TOASTER_TIMEOUT
    });

    return data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function fetchUserLogs(logs) {
  try {
    const accessToken = await checkAccessToken();
    if (!accessToken) {
      return;
    }
    const payload = { "data": { "log_type": logs } };
    const response = await fetch(urls.userLogs, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (response.status === 400) {
      toast.error(data.data.message, {
        position: 'top-right',
        autoClose: TOASTER_TIMEOUT
      });
    }
    return { data };
  } catch (error) {
    handleApiError(error);
  }
}