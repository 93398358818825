import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateUser, fetchAllUsers , removeUser, CreateFromUser, fetchAllFromUsers, removeFromUser ,CreateDomain , EditDomain , fetchAllDomains, fetchDomainData, fecthUserStatistics, fecthUserTemplates, removeUserTemplate, fetchUserLogs} from './adminApi';
import UserLogs from './components/userLogs';

const initialState = {
  users: [],
  fromUsers: [],
  status: 'idle',
  selectedUser: null ,
  allDomains : [],
  DomainData :[],
  userStatistics:[],
  userTemplates:[],
  userLogsData:null
};
 
export const createUserAsync = createAsyncThunk(
  'admin/CreateUser',
  async (userData) => {
    const response = await CreateUser(userData);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const createFromUserAsync = createAsyncThunk(
  'admin/CreateFromUser',
  async (fromUserData) => {
    const response = await CreateFromUser(fromUserData);
    return response.data;
  }
);

export const fetchAllUsersAsync = createAsyncThunk(
  'admin/fetchAllUsers',
  async ( ) => {
    const response = await fetchAllUsers();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchAllFromUsersAsync = createAsyncThunk(
  'admin/fetchAllFromUsers',
  async ( ) => {
    const response = await fetchAllFromUsers();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const removeUserAsync = createAsyncThunk(
  'admin/removeUser',
  async (id) => {
    const response = await removeUser(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const removeFromUserAsync = createAsyncThunk(
  'admin/removeFromUser',
  async (id) => {
    const response = await removeFromUser(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fecthAllDomainsAsync = createAsyncThunk(
  'admin/fetchAllDomains',
  async (id) => {
    const response = await fetchAllDomains(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const createDomainAsync = createAsyncThunk(
  'admin/CreateDomain',
  async (domainData) => {
    const response = await CreateDomain(domainData);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const EditDomainAsync = createAsyncThunk(
  'admin/EditDomain',
  async (domainData) => {
    const response = await EditDomain(domainData);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchAllDomainDataAsync = createAsyncThunk(
  'admin/fetchDomainData',
  async (id) => {
    const response = await fetchDomainData(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fecthUserStatisticsAsync = createAsyncThunk(
  'admin/fecthUserStatistics',
  async () => {
    const response = await fecthUserStatistics();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fecthUserTemplatesAsync = createAsyncThunk(
  'admin/fecthUserTemplates',
  async (id) => {
    const response = await fecthUserTemplates(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const removeUserTemplateAsync = createAsyncThunk(
  'admin/fecthUserTemplates',
  async (id) => {
    const response = await removeUserTemplate(id);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const fetchUserLogsAsync = createAsyncThunk(
  'admin/fetchUserLogs',
  async (data) => {
    const response = await  fetchUserLogs(data);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
   reducers: {
    updateDomainData: (state, action) => {
      state.DomainData = action.payload;
    }, 
    updateEditDomainData :(state, action) => {
      state.DomainData = [];
    },
  },
 
  extraReducers: (builder) => {
    builder
    .addCase(createUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createUserAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload
    })
    .addCase(fetchAllUsersAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAllUsersAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.users = action.payload;              
    })
    .addCase(removeUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(removeUserAsync.fulfilled, (state, action) => {       
      state.users = action.payload;
    })
    .addCase(createFromUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createFromUserAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(fetchAllFromUsersAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAllFromUsersAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.fromUsers = action.payload;
    })
    .addCase(removeFromUserAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(removeFromUserAsync.fulfilled, (state, action) => {
      state.fromUsers = action.payload;
    })
    .addCase(createDomainAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(createDomainAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(fetchAllDomainDataAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchAllDomainDataAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.DomainData = action.payload;
    })
    .addCase(fecthAllDomainsAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fecthAllDomainsAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.allDomains = action.payload;
    })
    .addCase(EditDomainAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(EditDomainAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.value = action.payload;
    })
    .addCase(fecthUserStatisticsAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fecthUserStatisticsAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.userStatistics = action.payload;
    })
    .addCase(fecthUserTemplatesAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fecthUserTemplatesAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.userTemplates = action.payload;
    })
    .addCase(fetchUserLogsAsync.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(fetchUserLogsAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.userLogsData = action.payload;
    })
  },
});
export const { updateDomainData , updateEditDomainData } = adminSlice.actions;


export const selectAllUsers = (state) => state.admin.users;
export const fetchAllusersData = (state)=>state.admin.selectedUser
export const selectAllFromUsers = (state) => state.admin.fromUsers;
export const fetchDomains = (state) => state.admin.allDomains;
export const fetchAllDomainData = (state) => state.admin.DomainData;
export const selectUserStatistics = (state) => state.admin.userStatistics;
export const selectUserTemplates = (state) => state.admin.userTemplates;
export const selectUserLogs = (state) => state.admin.userLogsData;

 
export default adminSlice.reducer;