import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  TablePagination,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import "./admin.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fecthUserStatisticsAsync,
  fecthUserTemplatesAsync,
  removeUserTemplateAsync,
  selectUserStatistics,
  selectUserTemplates,
} from "../adminSlice";
import { CircularProgress } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const generateLightColors = (count) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    const color = `hsl(${Math.floor(Math.random() * 360)}, 60%, ${
      Math.floor(Math.random() * 20) + 70
    }%)`;
    colors.push(color);
  }
  return colors;
};

const AdminTemplateControl = () => {
  const dispatch = useDispatch();
  const userStatistics = useSelector(selectUserStatistics);
  const userTemplates = useSelector(selectUserTemplates);

  console.log(userTemplates, "userTemplates userTemplates");

  const [loading, setLoading] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(false); // Loading state for user templates
  const [loadingAfterDelete, setLoadingAfterDelete] = useState(false); 
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [users, setUsers] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedUserId, setSelectedUserId]=useState(null)

  useEffect(() => {
    setLoading(true);
    dispatch(fecthUserStatisticsAsync())
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    if (userStatistics?.data?.fields) {
      setUsers(userStatistics.data.fields);
    }
  }, [userStatistics]);

  const filteredUsers = users?.filter((user) =>
    user.userEmail.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedUsers = filteredUsers?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const colors = generateLightColors(paginatedUsers?.length);

  const chartData = {
    labels: paginatedUsers?.map((user) => user.userEmail),
    datasets: [
      {
        data: paginatedUsers?.map((user) => user.totalTemplatesCount),
        backgroundColor: colors,
        hoverBackgroundColor: colors.map((color) =>
          color.replace("50%", "60%")
        ),
      },
    ],
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUserClick = (user) => {
    const id = user.userId;
    setLoadingTemplates(true); // Set loading state when fetching templates
    dispatch(fecthUserTemplatesAsync(id)).finally(() => {
      setLoadingTemplates(false); // Set loading to false after data is fetched
    });    setSelectedUserId(id)
    setSelectedUser(user);
    console.log(user.userId, " id");
    setOpenUserDialog(true);
  };

  const handleDeleteTemplate = (id) => {
     setSelectedTemplate(id);
    setOpenConfirmDialog(true);
  };

  const confirmDeleteTemplate = () => {
     dispatch(removeUserTemplateAsync(selectedTemplate))
     setOpenConfirmDialog(false);
          setLoadingTemplates(true);
          dispatch(fecthUserTemplatesAsync(selectedUserId));
          setLoadingTemplates(false);
          dispatch(fecthUserStatisticsAsync())

  };

  const handleSortTemplates = () => {
    const sortedUsers = [...users].sort((a, b) => {
      return sortDirection === "asc"
        ? a.totalTemplatesCount - b.totalTemplatesCount
        : b.totalTemplatesCount - a.totalTemplatesCount;
    });
    setUsers(sortedUsers);
    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  return (
    <Box sx={{ padding: "16px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container justifyContent="flex-start" sx={{ marginTop: "-3%" }}>
            <TextField
              variant="outlined"
              label="Search User"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={loading}
              sx={{
                width: "auto",
                marginTop: "7.4%",
                "& .MuiInputBase-root": { backgroundColor: "#f5f5f5" },
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  "& fieldset": { borderColor: "#ddd" },
                  "&:hover fieldset": { borderColor: "#1976d2" },
                  "&.Mui-focused fieldset": { borderColor: "#1976d2" },
                },
              }}
            />
          </Grid>
          <Paper elevation={3} sx={{ marginTop: "1%" }}>
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <>
                <TableContainer sx={{ maxHeight: "450px" }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: "bold", border: "1px solid #ddd" }}
                        >
                          User Email
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            fontWeight: "bold",
                            border: "1px solid #ddd",
                            cursor: "pointer",
                          }}
                          onClick={handleSortTemplates}
                        >
                          Number of Templates
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: "bold", border: "1px solid #ddd" }}
                        >
                          Total Size
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: "bold", border: "1px solid #ddd" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {paginatedUsers.length > 0 ? (
                        paginatedUsers.map((user, index) => (
                          <TableRow key={index}>
                            <TableCell
                              align="center"
                              sx={{ border: "1px solid #ddd" }}
                            >
                              {user.userEmail}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "1px solid #ddd" }}
                            >
                              {user.totalTemplatesCount}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "1px solid #ddd" }}
                            >
                              {user.totalTemplatesSize}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "1px solid #ddd" }}
                            >
                              <IconButton
                                color="primary"
                                onClick={() => handleUserClick(user)}
                              >
                                <Edit />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            align="center"
                            sx={{ border: "1px solid #ddd", padding: "20px" }}
                          >
                            No Data Available
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={filteredUsers.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  disabled={loading}
                />
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ padding: "16px", textAlign: "center", marginTop: "25%" }}
          >
            <Typography variant="h6" gutterBottom>
              Templates Distribution
            </Typography>
            {loading ? (
              <CircularProgress />
            ) : paginatedUsers.length > 0 ? (
              <Pie data={chartData} />
            ) : (
              <Typography>No Data Available</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={openUserDialog} onClose={() => setOpenUserDialog(false)}>
        <DialogTitle>{selectedUser?.userEmail}'s Templates</DialogTitle>
        {loadingTemplates ? (
  <Box sx={{ display: "flex", justifyContent: "center" }}>
    <CircularProgress />
  </Box>
) : (
  <DialogContent>
    {userTemplates?.data?.fields?.length > 0 ? (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell><strong>Template Name</strong></TableCell>
              <TableCell><strong>Size</strong></TableCell>
              <TableCell><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userTemplates.data.fields.map((template, index) => (
              <TableRow key={index}>
                <TableCell>{template.templateName}</TableCell>
                <TableCell>{template.totalTemplatesSize}</TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteTemplate(template.templateId)}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (
      <Typography>No Templates Available</Typography>
    )}
  </DialogContent>
)}

        <DialogActions>
          <Button onClick={() => setOpenUserDialog(false)} variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for Template Deletion */}
<Dialog open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)}>
  <DialogTitle>Confirm Deletion</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Are you sure you want to delete this template?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenConfirmDialog(false)} variant="outlined">
      Cancel
    </Button>
    <Button
      onClick={confirmDeleteTemplate} // Calls the existing confirmDeleteTemplate function
      color="error"
      variant="contained"
    >
      Confirm
    </Button>
    
  </DialogActions>
</Dialog>

    </Box>
  );
};

export default AdminTemplateControl;
